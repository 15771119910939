import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import { useLocalStorage } from "../../useLocalStorage";
import { upperCase, upperFirst } from "lodash";
function TablesTableRow(props) {
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const isSuperAdmin = auth?.roles?.superAdmin;
  const {
    logo,
    name,
    email,
    subdomain,
    domain,
    status,
    date,
    row,
    agentInfo,
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const nav = (
    <NavLink to={"/admin/profile/" + row.id}>
      <Button p="0px" bg="transparent" variant="no-hover">
        <Text fontSize="md" color="gray.400" fontWeight="bold" cursor="pointer">
          View Details
        </Text>
      </Button>
    </NavLink>
  );
  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            {isSuperAdmin && (
              <>
                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                  {row.email}
                </Text>
                {row.phone && (
                  <Text fontSize="sm" color="gray.400" fontWeight="normal">
                    {row.phone}
                  </Text>
                )}
                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                  {upperFirst(row.booking_types)}
                </Text>
              </>
            )}
            <Text
              display={{ sm: "block", md: "none" }}
              fontSize="sm"
              color="gray.400"
              fontWeight="normal"
            >
              {moment(
                row.lead_assigned_on
                  ? row.lead_assigned_on
                  : row.lead_created_on
              ).format("lll")}
            </Text>
            <Text
              display={{ sm: "block", md: "none" }}
              fontSize="sm"
              color="gray.400"
              fontWeight="normal"
            >
              Agent: {row.agent ? row.agent : "None"}
            </Text>
            <Text
              display={{ sm: "block", md: "none" }}
              fontSize="sm"
              color="gray.400"
              fontWeight="normal"
            >
              {nav}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td display={{ sm: "none", md: "table-cell" }}>
        <Badge
          bg={status === "Online" ? "green.400" : bgStatus}
          color={status === "Online" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {moment(
            row.lead_assigned_on ? row.lead_assigned_on : row.lead_created_on
          ).format("lll")}
        </Badge>
      </Td>

      {row.agent && (
        <Td display={{ sm: "none", md: "table-cell" }}>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {row.agent}
          </Text>
        </Td>
      )}
      {agentInfo && !row.agent && (
        <Td display={{ sm: "none", md: "table-cell" }}>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {row.agent}
          </Text>
        </Td>
      )}
      <Td display={"table-cell"}>{nav}</Td>
    </Tr>
  );
}

export default TablesTableRow;
