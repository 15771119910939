// Chakra imports
import {
  Flex,
  Grid,
  Text,
  useColorModeValue,
  Badge,
  Select,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Checkbox,
  CheckboxGroup,
  Stack,
} from "@chakra-ui/react";
import { FaTimes, FaTimesCircle } from "react-icons/fa";
// Custom components
import React, { useEffect, useState } from "react";
import { forEach, get, property, set, upperFirst, filter } from "lodash";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {
  useHistory,
  NavLink,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DynamicForm from "../../../../components/DynamicForm";
import FormData from "./FormData";
import { v4 as uuidv4 } from "uuid";

const CreateDeal = ({ onComplete, deal, form, fixWidth, generate, auth }) => {
  console.log({ deal });
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [visible, setVisible] = useState(true);
  const BLACK = "#000000";
  const GRAY = useColorModeValue("gray.300", "white");
  const { id } = useParams();
  const [mlsData, setMlsData] = useState(null);
  const [MLS, setMLS] = useState("E8070466");
  const [step, setStep] = useState("1");
  const [error, setError] = useState(false);
  const [editing, setEditing] = useState(false);
  const [propInfo, setPropInfo] = useState({});
  const [dealInfo, setDealInfo] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [uploading, setUploading] = useState(0);
  const [saving, setSaving] = useState(false);
  const history = useHistory();
  const title = "Create New Deal";
  useEffect(() => {
    if (formSubmitted) {
      if (!saving && uploading <= 0) {
        onComplete();
        setFormSubmitted(false);
      }
    }
  }, [saving, uploading, formSubmitted]);
  useEffect(() => {
    if (deal) {
      console.log("go to step 2");
      setMLS(deal.MLS);
      setDealInfo(deal);
      setStep("2");
    }
  }, [deal]);

  async function getMLSListingDetails(MLS) {
    let toSend;
    let resp = await (await fetch(`/api_v2/properties/${MLS}/`)).json();
    if (!resp.board_2 && !resp.board_16 && !resp.board_17) {
      console.log("WRONG MLS");
    } else {
      if (resp.board_2) {
        toSend = resp.board_2;
        console.log(resp);
      } else if (resp.board_16) {
        toSend = resp.board_16;
      } else {
        toSend = resp.board_17;
      }
    }
    console.log("debug", { toSend });

    if (toSend) {
      setMlsData(toSend);
    }
    return toSend;
  }
  async function handleMLSSubmit() {
    let resp = await getMLSListingDetails(MLS);
    if (!resp) {
      setError(true);
    } else {
      setStep("2");
      console.log("Correct MLS debug", { resp });
      if (resp.board_2) {
        resp = resp.board_2;
        console.log(resp);
      } else if (resp.board_16) {
        resp = resp.board_16;
      } else if (resp.board_17) {
        resp = resp.board_17;
      }
      const {
        unitNumber,
        streetNumber,
        streetName,
        streetSuffix,
        city,
        state,
        zip,
      } = resp.address;
      setPropInfo(resp);
      setDealInfo({
        MLS: resp._id,
        deal_type: resp.type,
        address:
          (unitNumber ? unitNumber + " " : "") +
          (streetNumber ? streetNumber + " " : "") +
          streetName +
          (streetSuffix ? " " + streetSuffix : "") +
          ", " +
          city +
          ", " +
          state +
          ", " +
          zip,
      });
      setError(false);
      setEditing(false);
    }
  }

  async function handleFormSubmit(formState, auth, type) {
    const fields = form ? form : FormData.form;
    let toSubmit = {};
    let toUpload = {};

    forEach(fields, (field) => {
      if (formState[field.name]) {
        if (field.type === "upload") {
          toUpload[field.name] = filter(formState[field.name], (file) => {
            return file.file && file.size;
          });
        } else {
          toSubmit[field.name] = formState[field.name];
        }
      }
    });
    const fetchURL = deal ? `/api/deals/${deal._id}` : `/api/deals`;
    console.log("debug", { toSubmit, toUpload });
    if (deal && Object.keys(toUpload).length > 0) {
      for (let key in toUpload) {
        setUploading(uploading + toUpload[key].length);
        for (let i = 0; i < toUpload[key].length; i++) {
          const uploads = {
            [key]: [
              {
                ...toUpload[key][i],
                id: uuidv4(),
                size: toUpload[key][i].file.size,
              },
            ],
          };
          console.log({
            fetchURL,
            body: {
              uploads,
            },
          });
          // Create UUID from FE
          // IF Key comes back then set it for UUID to update display of which file is done uploading
          // setUploading(uploading - 1);
          try {
            await fetch(fetchURL, {
              method: "PUT",
              body: JSON.stringify({
                uploads,
              }),
              headers: { dwelly_token: auth.token },
            });
            setUploading(uploading - 1);
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
    if (Object.keys(toSubmit).length > 0) {
      setSaving(true);

      fetch(fetchURL, {
        method: deal ? "PUT" : "POST",
        body: JSON.stringify(formState),
        headers: { dwelly_token: auth.token },
      })
        .then(() => {
          setSaving(false);
          //onComplete();
        })
        .catch((e) => console.log(e));
    }

    setFormSubmitted(true);
  }

  return (
    <Card p={form ? "" : "16px"} style={{ zIndex: 1 }}>
      {visible && (
        <CardBody px="5px">
          <Grid gap={form ? "0" : "24px"}>
            <Flex align="left" mb="18px" direction="column">
              {step !== "2" && (
                <>
                  <FormControl isInvalid={error}>
                    <Text fontWeight="bold">
                      Please Enter The MLS Number of the Associated Property
                    </Text>
                    <Input
                      fontSize="md"
                      color="gray.500"
                      display={"inline-block"}
                      fontWeight="400"
                      me="10px"
                      onChange={(e) => setMLS(e.target.value)}
                    />
                    <br />

                    <Button
                      onClick={handleMLSSubmit}
                      colorScheme="red"
                      mt="3"
                      size="md"
                    >
                      Continue
                    </Button>
                    <FormErrorMessage mt="2">Invalid MLS</FormErrorMessage>
                  </FormControl>
                </>
              )}

              {step === "2" && (
                <>
                  <FormControl isRequired isInvalid={error}>
                    {!editing && !form ? (
                      <>
                        <FormLabel>MLS Number</FormLabel>
                        <Stack direction={"row"}>
                          <Input
                            variant="filled"
                            type="text"
                            mb="5"
                            value={dealInfo.MLS}
                          />
                          <Button
                            onClick={() => setEditing(true)}
                            colorScheme="red"
                            mt="3"
                            size="md"
                          >
                            Edit
                          </Button>
                        </Stack>
                      </>
                    ) : null}

                    {editing && (
                      <>
                        <FormLabel>MLS Number</FormLabel>
                        <Stack m="0" p="0" direction={"row"}>
                          <Input
                            type="text"
                            onChange={(e) => setMLS(e.target.value)}
                            value={MLS}
                          />
                          <Button
                            onClick={handleMLSSubmit}
                            colorScheme="red"
                            mt="3"
                            size="md"
                          >
                            Continue
                          </Button>
                        </Stack>
                        <FormErrorMessage mt="2" mb="2">
                          Invalid MLS
                        </FormErrorMessage>
                      </>
                    )}
                  </FormControl>

                  {!form && (
                    <FormControl isRequired>
                      <FormLabel>Address</FormLabel>
                      <Input
                        variant="filled"
                        type="text"
                        mb="5"
                        value={dealInfo.address}
                      />
                    </FormControl>
                  )}

                  <DynamicForm
                    fixWidth={fixWidth}
                    formData={form ? form : FormData.form}
                    onFormSubmit={handleFormSubmit}
                    inline={true}
                    btnText={"Save"}
                    data={dealInfo}
                    viewOnly={editing ? deal?.locked : false}
                    saving={saving}
                    generate={generate}
                    uploading={uploading}
                    auth={auth}
                  />
                </>
              )}
            </Flex>
          </Grid>
        </CardBody>
      )}
    </Card>
  );
};

export default CreateDeal;
