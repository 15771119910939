// Chakra Imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Icon,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import GitHubButton from "react-github-btn";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import { useLocalStorage } from "useLocalStorage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

export default function Configurator(props) {
  const history = useHistory();

  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const [switched, setSwitched] = useState(props.isChecked);
  const [me, setMe] = useState(null);

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();
  async function loadMe() {
    const me = await fetch("/api/agents/me", {
      headers: { dwelly_token: auth.token },
    });
    const json = await me.json();
    if (me.status === 401) {
      setAuth(null);
      history.push("/auth/signin");
    } else {
      setMe(json);
    }
  }
  useEffect(() => {
    if (!auth) {
      history.push("/auth/signin");
    } else {
      loadMe();
    }
  }, [auth]);
  console.log({ auth, me });

  function copyText(txt) {
    navigator.clipboard.writeText(txt);
    toast.success(`copied`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
      >
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              Dwelly Agent Admin
            </Text>
            {me && (
              <>
                <Text fontSize="md" mb="0px">
                  {me.user.name}
                </Text>
                <Text
                  onClick={() => copyText(me.isAgent.voip.sip.account)}
                  fontWeight="light"
                  fontSize="sm"
                  mb="0px"
                >
                  {me.isAgent?.voip?.sip?.account &&
                    "SIP:" + me.isAgent.voip.sip.account}
                </Text>
                {me.isAgent?.voip?.sip?.sip && (
                  <Text
                    onClick={() =>
                      copyText(
                        me.isAgent.voip.sip.sip.substring(4).split("@")[0]
                      )
                    }
                    fontWeight="light"
                    fontSize="sm"
                    mb="0px"
                  >
                    Linphone:{" "}
                    {me.isAgent.voip.sip.sip.substring(4).split("@")[0]}
                  </Text>
                )}
                <Text
                  onClick={() => copyText(me.isAgent.voip.sip.password)}
                  fontWeight="light"
                  fontSize="sm"
                  mb="16px"
                >
                  Password: {me.isAgent?.voip?.sip?.password}
                </Text>
              </>
            )}
            <Separator />
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">
              {false && (
                <Box>
                  <Text fontSize="md" fontWeight="600">
                    Sidenav Type
                  </Text>
                  <Text fontSize="sm" mb="16px">
                    Choose between 2 different sidenav types.
                  </Text>
                  <Flex>
                    <Button
                      w="50%"
                      p="8px 32px"
                      me="8px"
                      colorScheme="teal"
                      borderColor="teal.300"
                      color="teal.300"
                      variant="outline"
                      fontSize="xs"
                      onClick={props.onTransparent}
                    >
                      Transparent
                    </Button>
                    <Button
                      type="submit"
                      bg="teal.300"
                      w="50%"
                      p="8px 32px"
                      mb={5}
                      _hover="teal.300"
                      color="white"
                      fontSize="xs"
                      onClick={props.onOpaque}
                    >
                      Opaque
                    </Button>
                  </Flex>
                </Box>
              )}
              {false && (
                <Box
                  display={fixedDisplay}
                  justifyContent="space-between "
                  mb="16px"
                >
                  <Text fontSize="md" fontWeight="600" mb="4px">
                    Navbar Fixed?
                  </Text>
                  <Switch
                    colorScheme="teal"
                    isChecked={switched}
                    onChange={(event) => {
                      if (switched === true) {
                        props.onSwitch(false);
                        setSwitched(false);
                      } else {
                        props.onSwitch(true);
                        setSwitched(true);
                      }
                    }}
                  />
                </Box>
              )}

              <Box mt="24px">
                <Box>
                  <Button
                    onClick={() => {
                      console.log("logout");
                      //props.onClose()
                      setAuth(null);
                    }}
                    w="100%"
                    mb="16px"
                    bg={bgButton}
                    color={colorButton}
                    fontSize="xs"
                    variant="no-hover"
                    px="30px"
                  >
                    Logout
                  </Button>
                </Box>
              </Box>
              <Separator />
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="24px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  Dark/Light
                </Text>
                <Button onClick={toggleColorMode}>
                  Toggle {colorMode === "light" ? "Dark" : "Light"}
                </Button>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
