import React from "react";
import { useEffect, useState, useId } from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "../../../../assets/img/Dwelly_FullLogo_Transparent.png";
import moment from "moment";
import FontVerdana from "../../../../assets/fonts/Verdana.ttf";
import FontVerdanaBold from "../../../../assets/fonts/Verdana Bold.ttf";
import FontVerdanaItalic from "../../../../assets/fonts/Verdana Italic.ttf";
import FontVerdanaBoldItalic from "../../../../assets/fonts/Verdana Bold Italic.ttf";
import { last } from "lodash";
import DealCalculations from "./DealCalculations";
Font.register({
  family: "Verdana",
  fonts: [
    { src: FontVerdana },
    { src: FontVerdanaBold, fontWeight: "bold" },
    { src: FontVerdanaItalic, fontStyle: "italic" },
    { src: FontVerdanaBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Verdana",
    padding: 20,
  },
  text: {
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    paddingLeft: 25,
    paddingRight: 25,
  },
});

// Create Document Component
const formatCurrency = (amount) => {
  return `$${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};
const Invoice = ({ deal, allAgents }) => {
  const [mlsData, setMlsData] = useState(null);
  const {
    agents,
    primaryAgent,
    dwellyRebate,
    otherAgents,
    salePrice,
    buyerCommissionPercentage,
    sellerCommissionPercentage,
    totalCommissionPercentage,
    buyerCommission,
    sellerCommission,
    primaryAgentCommission,
    totalCommission,
    totalCommissionHST,
    otherAgentCommissions,
    primaryAgentEmail,
    primaryAgentInfo,
    coopRebate,
    sellerRebate,
    agentComissions,
    totalAgentCommissions,
    officeCommission,
    personaSaleFee,
    HST,
    isco_op,
    rebate,
    hasRebate,
    Comission,
    Expenses,
    BaseOfficeCommission,
    AgentsLines,
    AgentTotalsLine,
    NetOfficeLine,
  } = DealCalculations(deal, allAgents);
  useEffect(() => {
    if (deal && !mlsData) {
      getMLSListingDetails(deal.MLS);
    }
  }, [deal]);
  async function getMLSListingDetails(MLS) {
    let toSend;
    let resp = await (await fetch(`/api_v2/properties/${MLS}/`)).json();
    if (!resp.board_2 && !resp.board_16 && !resp.board_17) {
      console.log("WRONG MLS");
    } else {
      if (resp.board_16) {
        toSend = resp.board_16;
        console.log(resp);
      } else if (resp.board_2) {
        toSend = resp.board_2;
      } else {
        toSend = resp.board_17;
      }
    }
    if (toSend) {
      setMlsData(toSend);
    }
    console.log({ toSend });
    return toSend;
  }
  const doc = mlsData && (
    <Document>
      <Page size={"LETTER"} style={styles.page}>
        <View style={styles.section}>
          <Image style={{ width: "30%" }} src={Logo} />
          <Text style={styles.text}>{moment().format("LL")}</Text>
          <Text style={styles.text}>
            {mlsData.office && mlsData.office.brokerageName
              ? mlsData.office.brokerageName
              : "Name of Brokerage"}
          </Text>
          <Text style={styles.text}>
            {mlsData.agents &&
            mlsData.agents[0] &&
            mlsData.agents[0].brokerage &&
            mlsData.agents[0].brokerage.address &&
            mlsData.agents[0].brokerage.address.address1
              ? mlsData.agents[0].brokerage.address.address1
              : "Address of Brokerage"}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.text, fontWeight: "extrabold" }}>
            Re: Advance Statement of Account
          </Text>
          <Text style={{ ...styles.text, fontWeight: "extrabold" }}>
            {deal.address}
          </Text>
          <Text style={{ ...styles.text, fontWeight: "extrabold" }}>
            Closing Scheduled for {moment(deal.closing_date).format("LL")}
          </Text>
        </View>
        <View style={{ ...styles.section, width: "50%" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ ...styles.text }}>Sale Price:</Text>
            <Text style={{ ...styles.text }}>
              {formatCurrency(parseFloat(deal.sale_price))}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ ...styles.text }}>
              Comission: {deal.total_commission_buyagent}%
            </Text>
            <Text style={{ ...styles.text }}>
              {formatCurrency(totalCommission)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ ...styles.text }}>HST: {HST}%</Text>
            <Text style={{ ...styles.text }}>
              {formatCurrency(totalCommissionHST)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1px solid black",
            }}
          >
            <Text style={{ ...styles.text, fontWeight: "extrabold" }}>
              Balance Due on Closing:
            </Text>
            <Text style={{ ...styles.text, fontWeight: "extrabold" }}>
              {formatCurrency(totalCommissionHST + totalCommission)}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.section, paddingTop: 0, paddingBottom: 0 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ ...styles.text }}>
              Our invoice number is:{" "}
              {moment(deal.closing_date).format("YYYYMM-") +
                last(deal._id.split("-"))}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ ...styles.text }}>Our GST/HST # is 714382512RT</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, fontStyle: "italic" }}>
            We prefer direct deposit or Electronics Funds Transfer (EFT), please
            email us so we can provide your office our banking information.
            Email:{" "}
            <Text style={{ textDecoration: "underline" }}>
              deals@dwellyrealty.com
            </Text>
          </Text>
          <Text
            style={{
              ...styles.text,
              fontStyle: "italic",
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            Please note: Our financial institution has become stricter when
            accepting cheques. If the cheque is missing any part of our
            brokerage name, it will not be accepted. Please ensure the cheque is
            made payable to "DWELLY REALTY INC".
          </Text>
          <Text
            style={{
              ...styles.text,
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            Please make the cheque payable to{" "}
            <Text style={{ color: "red" }}>DWELLY REALTY INC.</Text> and forward
            to the following address:
          </Text>
          <View
            style={{
              ...styles.text,
              marginTop: 20,
              marginLeft: 20,
            }}
          >
            <Text style={{ fontWeight: "bold" }}>DWELLY REALTY INC.</Text>
            <Text>66 Commodore Drive</Text>
            <Text>Brampton, ON</Text>
            <Text>L6X 0S6</Text>
          </View>
          <Text
            style={{
              ...styles.text,
              marginTop: 40,
            }}
          >
            Yours truly
          </Text>
          <Text
            style={{
              ...styles.text,
            }}
          >
            {"\n"}Dwelly Admin
          </Text>
          <Text
            style={{
              ...styles.text,
              textAlign: "center",
              marginTop: 40,
            }}
          >
            {"\n"}
            <Text style={{ fontWeight: "bold" }}>
              Dwelly Realty Inc., Brokerage
            </Text>
            {"\n"}405 The West Mall, Suit 910, Toronto, Ontario M9C 5J1 {"\n"}
            Tel: 647.797.1133 -- Email: deals@dwellyrealty.com
          </Text>
        </View>
      </Page>
    </Document>
  );
  const print = (
    <PDFDownloadLink
      style={{ position: "absolute", top: 20, left: 200 }}
      document={doc}
      fileName={"invoice_" + deal.deal_id + "_" + deal._id + ".pdf"}
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now!"
      }
    </PDFDownloadLink>
  );
  return (
    mlsData && (
      <>
        {print}
        <PDFViewer style={{ flexGrow: 1, width: "100%", height: "90vh" }}>
          {doc}
        </PDFViewer>
      </>
    )
  );
};
export default Invoice;
