// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Switch,
  Select,
  Grid,
  GridItem,
  Button,
  Spacer,
  Tooltip,
  Badge,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";
import moment from "moment";
import DealCalculations from "./DealCalculations";

/*
TODO:
1.  Once agent is set don't allow edit of MSL number
2.  Allow of obtaining "Trade Sheet" for the deal
3.  Generate Deposit Split PDF
4.  Generate One PDF for all uploads
5.  Track Transactions
      Deposit - Real Estate Trust
      Commissions - From Real Estate Trust to Commission Trust & From Commission Trust to Agents & Office
      Return - From Real Estate Trust to Seller
6.  Sometimes deals don't show properly
*/
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "../../../../components/Tables/TablesTableRowDeals";
import React, { useEffect, useState, useId } from "react";
import CreateDeal from "./CreateDeal";
import { useHistory } from "react-router-dom";
import { upperCase, upperFirst, last } from "lodash";
import {
  Page,
  Text as PDFText,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import FormDataAdmin from "./FormDataAdmin";
import Invoice from "./InvoiceToSeller";
import Deposit from "./DepositToBuyer";
import TradeSheet from "./TradeSheet";
import Cheque from "./Cheque";
import Rebate from "./Rebate";
import Transactions from "./DealTransactions";
import AllTransactions from "./AllTransactions";
const CurrencyFormatter = (value) => {
  return new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  }).format(value);
};
const Authors = ({
  title,
  captions,
  data,
  status,
  setStatus,
  allAgents,
  selectAgent,
  reLoad,
  dealId,
  deal,
  reSet,
  auth,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modal, setModal] = useState(null);
  //const [status, setStatus] = useState(true)
  const [editing, setEditing] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (dealId) {
      setEditing(true);
    }
  }, [dealId]);
  useEffect(() => {
    if (modal && modal.title && modal.body) {
      onOpen();
    } else {
      onClose();
    }
  }, [modal]);
  console.log({ dealId, editing, captions, auth });
  const textColor = useColorModeValue("gray.700", "white");
  const onComplete = () => {
    setEditing(false);
    reLoad();
    history.push("/admin/deals");
  };
  const showTransactions = () => {
    setModal({
      title: "Transactions " + (deal.deal_id ? deal.deal_id : ""),
      body: (
        <Transactions
          dealId={dealId}
          deal={deal}
          allAgents={allAgents}
          viewOnly={!auth?.roles?.superAdmin}
        />
      ),
    });
  };
  const showAllTransactions = () => {
    setModal({
      title: "Transactions ",
      body: <AllTransactions />,
    });
  };
  const lockDeal = () => {
    fetch(`/api/deals/${dealId}/lock`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        dwelly_token: auth.token,
      },
      body: {},
    });
  };
  const unlockDeal = () => {
    fetch(`/api/deals/${dealId}/unlock`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        dwelly_token: auth.token,
      },
      body: {},
    });
  };

  const table = (
    <Table variant="simple" color={textColor}>
      <Thead>
        <Tr my=".8rem" pl="0px" color="gray.400">
          {captions.map((caption, idx) => {
            return (
              <Th
                display={{
                  sm: idx === 0 ? "table-cell" : "none",
                  md: "table-cell",
                }}
                color="gray.400"
                key={"c_" + idx}
                ps={idx === 0 ? "0px" : null}
              >
                {upperCase(caption)}
              </Th>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, id) => {
          return (
            <TablesTableRow
              title={title}
              captions={captions}
              agentInfo={captions[2] === "Agent"}
              key={`dealRow$_${id}`}
              name={row.name}
              logo={row.logo}
              email={row.email}
              subdomain={row.subdomain}
              domain={row.domain}
              status={row.status}
              date={row.date}
              row={row}
            />
          );
        })}
      </Tbody>
    </Table>
  );
  const {
    agents,
    primaryAgent,
    dwellyRebate,
    otherAgents,
    salePrice,
    buyerCommissionPercentage,
    sellerCommissionPercentage,
    totalCommissionPercentage,
    buyerCommission,
    sellerCommission,
    primaryAgentCommission,
    totalCommission,
    totalCommissionHST,
    otherAgentCommissions,
    primaryAgentEmail,
    primaryAgentInfo,
    coopRebate,
    sellerRebate,
    agentComissions,
    totalAgentCommissions,
    officeCommission,
    personaSaleFee,
    HST,
    isco_op,
    rebate,
    hasRebate,
    Comission,
    Expenses,
    BaseOfficeCommission,
    AgentsLines,
    AgentTotalsLine,
    NetOfficeLine,
  } = DealCalculations(deal, allAgents);
  const generate = (type) => {
    let values = [];
    if (type === "transactions") {
      if (!isco_op) {
        values.push({
          account: "real_estate_trust",
          details: "Buyer Deposit ",
          amount: 0,
          type: "debit",
        });

        values.push({
          account: "real_estate_trust",
          details: "Commissions",
          amount: Math.round(last(Comission) * 100) / 100,
          type: "credit",
          to: "commission_trust",
        });
      }
      if (isco_op) {
        values.push({
          account: "commission_trust",
          details:
            "Invoice Payment from " +
            (deal.other_brokerages && deal.other_brokerages.length > 0)
              ? deal.other_brokerages[0].name
              : "Other Brokerage",
          reference:
            moment(deal.closing_date).format("YYYYMM-") +
            last(deal._id.split("-")),
          amount: last(Comission),
          type: "debit",
        });
      } else {
        values.push({
          account: "commission_trust",
          details:
            "Payment to  " +
            (deal.other_brokerages && deal.other_brokerages.length > 0
              ? deal.other_brokerages[0].name
              : "Other Brokerage"),
          amount: Math.round(last(Expenses) * 100) / 100,
          type: "credit",
          to: "agent_other",
        });
      }
      if (hasRebate) {
        values.push({
          account: "commission_trust",
          details: (isco_op ? "Buyer" : "Seller") + " Rebate",
          amount: dwellyRebate,
          type: "credit",
          to: "buyer_rebate",
        });
      }
      AgentsLines.map((line) => {
        values.push({
          account: "commission_trust",
          details: line[0],
          amount: Math.round(last(line) * 100) / 100,
          type: "credit",
          to: "agent_dwelly",
          reference: line[0],
        });
      });
      values.push({
        account: "commission_trust",
        details: "Office Commission",
        amount: NetOfficeLine[NetOfficeLine.length - 3],
        type: "credit",
        to: "dwelly",
      });
      values.push({
        account: "commission_trust",
        details: "Office Commission  HST",
        amount: NetOfficeLine[NetOfficeLine.length - 2],
        type: "credit",
        to: "dwelly",
      });
    }
    return values;
  };
  const old = (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {editing ? (dealId ? "Update " : "Add ") : ""}
          {title}
          {deal?.locked ? " (Locked)" : ""}
        </Text>
        <Spacer />
        {editing ? (
          <>
            {dealId && (
              <Button
                onClick={() => showTransactions()}
                colorScheme="red"
                marginLeft={"10px"}
                alignSelf={"right"}
              >
                Transactions
              </Button>
            )}
            <Button
              onClick={() => {
                deal?.locked ? unlockDeal() : lockDeal();
                onComplete();
              }}
              colorScheme="red"
              marginLeft={"10px"}
              alignSelf={"right"}
            >
              <LockIcon marginRight={2} />
              {deal?.locked ? "Unlock" : "Lock"}
            </Button>
            <Button
              onClick={() => onComplete()}
              colorScheme="red"
              marginLeft={"10px"}
              alignSelf={"right"}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            {auth && auth.roles && auth.roles.superAdmin && (
              <Button
                onClick={() => showAllTransactions()}
                colorScheme="teal"
                marginLeft={"10px"}
                alignSelf={"right"}
              >
                View All Transactions
              </Button>
            )}
            <Button
              onClick={() => {
                reSet();
                setEditing(true);
              }}
              colorScheme="teal"
              marginLeft={"10px"}
              alignSelf={"right"}
            >
              Add Deal
            </Button>
          </>
        )}
      </CardHeader>
      <CardBody>
        <Grid
          width={"100%"}
          templateColumns={"repeat(6, 1fr)"}
          templateRows={"repeat(2, 1fr)"}
        >
          <GridItem colSpan={editing ? 4 : 6}>
            {editing ? (
              <CreateDeal
                key={"main_" + dealId}
                onComplete={onComplete}
                deal={deal}
                fixWidth={true}
              />
            ) : (
              table
            )}
          </GridItem>

          {editing && deal && deal.notes && (
            <GridItem colSpan={2}>
              <Text>Summary</Text>
              <Text fontSize={"x-small"}>
                Please save to see updated values
              </Text>
              <CreateDeal
                key={"side_" + dealId}
                onComplete={onComplete}
                deal={deal}
                form={FormDataAdmin.form}
                generate={generate}
                auth={auth}
              />
              <Text fontSize={"sm"}>Transaction Type: {deal.deal_type}</Text>
              <Text fontSize={"sm"}>
                Sale Price: {CurrencyFormatter(deal.sale_price)}
              </Text>
              <Text fontSize={"sm"}>
                Total Commission:{CurrencyFormatter(totalCommission)}
              </Text>
              <Text fontSize={"sm"}>
                Buyer Side Commission:{CurrencyFormatter(buyerCommission)}
              </Text>
              <Text fontSize={"sm"}>
                Seller Side Commission:{CurrencyFormatter(sellerCommission)} (
                {sellerCommissionPercentage}%)
              </Text>
              <Text fontSize={"sm"}>
                Office Commission:{CurrencyFormatter(officeCommission)}
              </Text>
              <Text fontSize={"sm"}>
                Dwelly Primary Agent: {upperFirst(primaryAgent)}{" "}
                {CurrencyFormatter(primaryAgentCommission)}
              </Text>{" "}
              <Text fontSize={"sm"}>
                Dwelly Rebate:
                {CurrencyFormatter(dwellyRebate)}
              </Text>
              {otherAgents.map((agent) => {
                const name = agent.split("@")[0];
                return (
                  <Text fontSize={"sm"}>
                    Dwelly Other Agent: {upperFirst(name)}{" "}
                    {deal.commission_agents && deal.commission_agents[name]
                      ? CurrencyFormatter(deal.commission_agents[name])
                      : CurrencyFormatter(0)}
                  </Text>
                );
              })}
              {deal.agent_type === "co_op" && (
                <Button
                  size={"xs"}
                  onClick={() => {
                    setModal({
                      title: "Invoice",
                      body: <Invoice deal={deal} allAgents={allAgents} />,
                    });
                  }}
                >
                  Get Invoice
                </Button>
              )}
              {deal.agent_type !== "co_op" && (
                <Button
                  size={"xs"}
                  onClick={() => {
                    setModal({
                      title: "Deposit Receipt",
                      body: <Deposit deal={deal} />,
                    });
                  }}
                >
                  Get Deposit Receipt
                </Button>
              )}
              {hasRebate && (
                <Button
                  size={"xs"}
                  onClick={() => {
                    setModal({
                      title: "Dwelly Rebate Agreement",
                      body: <Rebate deal={deal} allAgents={allAgents} />,
                    });
                  }}
                >
                  Get Rebate Agreement
                </Button>
              )}
              <Button
                size={"xs"}
                onClick={() => {
                  setModal({
                    title: "Trade Sheet",
                    body: <TradeSheet deal={deal} allAgents={allAgents} />,
                  });
                }}
              >
                Get Trade Sheet
              </Button>
              <Button
                size={"xs"}
                onClick={() => {
                  setModal({
                    title: "Cheque",
                    body: <Cheque deal={deal} allAgents={allAgents} />,
                  });
                }}
              >
                Get Cheque
              </Button>
              <Text>Notes</Text>
              {deal.notes.map((note, idx) => {
                return <Note key={idx + "_n"} note={note} />;
              })}
            </GridItem>
          )}
        </Grid>
      </CardBody>
    </Card>
  );

  return (
    <>
      {old}
      <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modal && modal.title ? modal.title : null}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modal && modal.body ? modal.body : null}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Authors;
const Note = ({ note }) => {
  const txt = (
    <Text fontSize="sm" color="gray.500" fontWeight="400">
      {note.note} {note.who ? "by " + note.who : ""}
    </Text>
  );
  return note.created_at ? (
    <Tooltip label={moment(note.created_at).format("lll")}>{txt}</Tooltip>
  ) : (
    txt
  );
};
