import React from "react";
import { useEffect, useState, useId } from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import ChequeBG from "../../../../assets/ChequeTemplate.jpg";
import Logo from "../../../../assets/img/Dwelly_FullLogo_Transparent.png";
import moment from "moment";
import FontVerdana from "../../../../assets/fonts/Verdana.ttf";
import FontVerdanaBold from "../../../../assets/fonts/Verdana Bold.ttf";
import FontVerdanaItalic from "../../../../assets/fonts/Verdana Italic.ttf";
import FontVerdanaBoldItalic from "../../../../assets/fonts/Verdana Bold Italic.ttf";
import { last, upperFirst, filter, startCase, set } from "lodash";
import DealCalculations from "./DealCalculations";
import { useLocalStorage } from "useLocalStorage";
import { Button, Select } from "@chakra-ui/react";
Font.register({
  family: "Verdana",
  fonts: [
    { src: FontVerdana },
    { src: FontVerdanaBold, fontWeight: "bold" },
    { src: FontVerdanaItalic, fontStyle: "italic" },
    { src: FontVerdanaBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});
function numberToWords(amount) {
  const ones = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const thousands = ["", "thousand", "million", "billion"];

  if (amount === 0) return "zero dollars";

  let result = "";
  let dollars = Math.floor(amount);
  let cents = Math.round((amount - dollars) * 100);

  let i = 0;
  while (dollars > 0) {
    if (dollars % 1000 !== 0) {
      result = helper(dollars % 1000) + " " + thousands[i] + " " + result;
    }
    dollars = Math.floor(dollars / 1000);
    i++;
  }

  result = result.trim();

  if (cents > 0) {
    result += " and " + cents + "/100 dollars";
  } else {
    result += " 00/100 dollars";
  }

  return result;
}

function helper(num) {
  const ones = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  if (num === 0) return "";

  if (num < 10) return ones[num];
  if (num < 20) return teens[num - 10];
  if (num < 100)
    return (
      tens[Math.floor(num / 10)] + (num % 10 === 0 ? "" : " " + ones[num % 10])
    );
  return (
    ones[Math.floor(num / 100)] +
    " hundred" +
    (num % 100 === 0 ? "" : " and " + helper(num % 100))
  );
}
const CurrencyFormatter = (value) => {
  return new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  }).format(value);
};
// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Verdana",
    fontSize: 7,
    flexDirection: "column",
    columnGap: 10,
  },
  amount: {
    fontSize: 10,
    textAlign: "left",
    position: "absolute",
    top: 106,
    left: 50,
  },
  date: {
    fontSize: 10,
    textAlign: "left",
    position: "absolute",
    top: 75,
    left: 420,
  },
  amount_num: {
    fontSize: 10,
    textAlign: "left",
    position: "absolute",
    top: 106,
    left: 480,
  },
  name: {
    fontSize: 10,
    textAlign: "left",
    position: "absolute",
    top: 150,
    left: 60,
  },
  re: {
    fontSize: 8,
    textAlign: "left",
    position: "absolute",
    top: 200,
    left: 25,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  sectionTwo: {
    fontSize: 10,
    left: 50,
    position: "absolute",
    top: 300,
  },
  sectionThree: {
    fontSize: 10,
    left: 50,
    position: "absolute",
    top: 600,
  },
  view: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  view2: {
    flexDirection: "column",
  },
  header: {
    fontSize: 14,
    height: 20,
    fontWeight: "bold",
  },
  header2: {
    fontSize: 8,
    fontWeight: "bold",
  },
  subheader: {
    fontSize: 8,
    fontWeight: "bold",
  },
  text: {
    fontSize: 8,
    fontWeight: "normal",
  },
});

// Create Document Component
const formatCurrency = (amount) => {
  return `$${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};
const Invoice = ({ deal, allAgents }) => {
  const [auth, setAuth] = useLocalStorage("auth_v2", null);

  const [mlsData, setMlsData] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [RSTrust, setRSTrust] = useState([]);
  const [CTrust, setCTrust] = useState([]);
  const [General, setGeneral] = useState([]);
  const types = ["rebate", "seller", "brokerage"];
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [re, setRe] = useState("");
  const dealId = deal && deal._id;
  useEffect(() => {
    if (type === "rebate") {
      setAmount(dwellyRebate);
      setName(deal.buyers.map((buyer) => buyer.name).join(" and "));
      setRe("Buyer Rebate: " + deal.address);
    } else if (type === "seller") {
      setAmount(
        parseFloat(deal.buyer_deposit) - totalCommission - totalCommissionHST
      );
      setName(deal.sellers.map((seller) => seller.name).join(" and "));
      setRe("Re: " + deal.address);
    } else if (type === "brokerage") {
      setAmount(buyerCommission + buyerCommission * HST);
      setName(deal.other_brokerages[0].name);
      setRe("Re: " + deal.address);
    }
  }, [type]);
  useEffect(() => {
    fetch(`/api/deals/${dealId}/transactions`, {
      headers: {
        dwelly_token: auth.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTransactions(data);
      })
      .catch((e) => {
        console.log(e);
        setTransactions([]);
      });
  }, [dealId, auth]);
  useEffect(() => {
    if (dealId && transactions && transactions.length > 0) {
      setRSTrust(filter([...transactions], { account: "real_estate_trust" }));
      setCTrust(filter([...transactions], { account: "commission_trust" }));
      setGeneral(filter([...transactions], { account: "general" }));
    }
    console.log("debug_txn", { transactions });
  }, [dealId, transactions]);
  async function getMLSListingDetails(MLS) {
    let toSend;
    let resp = await (await fetch(`/api_v2/properties/${MLS}/`)).json();
    if (!resp.board_2 && !resp.board_16 && !resp.board_17) {
      console.log("WRONG MLS");
    } else {
      if (resp.board_16) {
        toSend = resp.board_16;
        console.log(resp);
      } else if (resp.board_2) {
        toSend = resp.board_2;
      } else {
        toSend = resp.board_17;
      }
    }
    if (toSend) {
      setMlsData(toSend);
    }
    console.log({ toSend });
    return toSend;
  }
  const {
    agents,
    primaryAgent,
    dwellyRebate,
    otherAgents,
    salePrice,
    buyerCommissionPercentage,
    sellerCommissionPercentage,
    totalCommissionPercentage,
    buyerCommission,
    sellerCommission,
    primaryAgentCommission,
    totalCommission,
    totalCommissionHST,
    otherAgentCommissions,
    primaryAgentEmail,
    primaryAgentInfo,
    coopRebate,
    sellerRebate,
    agentComissions,
    totalAgentCommissions,
    officeCommission,
    personaSaleFee,
    HST,
    isco_op,
    rebate,
    hasRebate,
    Comission,
    Expenses,
    BaseOfficeCommission,
    AgentsLines,
    AgentTotalsLine,
    NetOfficeLine,
  } = DealCalculations(deal, allAgents);
  useEffect(() => {
    if (deal && !mlsData) {
      getMLSListingDetails(deal.MLS);
    }
  }, [deal]);
  const contact = {
    headings: {
      id: "Type",
      name: "Name",
      phone: "Phone",
      address: "Address",
      email: "Email",
    },
    fields: ["id", "name", "phone", "address", "email"],
    spacing: ["10%", "30%", "15%", "30%", "15%"],
  };
  const brokers = {
    headings: {
      id: "Type",
      name: "Name",
      phone: "Phone",
      address: "Address",
      agent_name: "Agent",
    },
    fields: ["id", "name", "phone", "address", "agent_name"],
    spacing: ["10%", "30%", "15%", "30%", "15%"],
  };
  const financials = {
    spacing: ["30%", "10%", "10%", "10%", "10%", "10%", "10%", "10%"],
    textAlign: [
      "left",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
      "right",
    ],
  };
  const accountActivity = {
    spacing: ["40%", "20%", "20%", "20%"],
    textAlign: ["left", "left", "left", "right"],
  };
  const rowsRender = (person, info, empty, bold, fontSize) => {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        {info.fields.map((field, idx) => {
          const style = {
            textAlign:
              info.textAlign && info.textAlign[idx]
                ? info.textAlign[idx]
                : "left",
            width: info.spacing[idx],
            fontWeight: bold ? "bold" : "normal",
          };
          if (fontSize) style.fontSize = fontSize;
          return (
            <Text style={style}>
              {person && person[field] ? person[field] : empty ? field : ""}
            </Text>
          );
        })}
      </View>
    );
  };
  const data = (
    <>
      <Text>Paid To: {name}</Text>
      <Text>Date: {moment().format("LL")}</Text>
      <Text>Amount: {CurrencyFormatter(amount)}</Text>
      <Text>
        Seller/s: {deal.sellers.map((seller) => seller.name).join(", ")}
      </Text>
      <Text>
        Buyer/s: {deal.buyers.map((seller) => seller.name).join(", ")}
      </Text>
      <Text>Note: {re}</Text>
      <Text>
        REF: {deal._id} ({deal.MLS}-{deal.deal_id})
      </Text>
    </>
  );
  const MyDoc = ({ type }) => (
    <Document>
      <Page size={"LETTER"} style={styles.page}>
        {type !== "print" && (
          <Image
            src={ChequeBG}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        )}

        <View>
          <Text style={styles.amount}>
            {numberToWords(amount)
              .split(" ")
              .map((l) => upperFirst(l))
              .join(" ")}
          </Text>
          <Text style={styles.amount_num}>
            {CurrencyFormatter(amount).replace("$", "")}
          </Text>
          <Text style={styles.date}>{moment().format("LL")} </Text>
          <Text style={styles.name}>{name} </Text>
          <Text style={styles.re}>{re} </Text>
        </View>
        <View style={styles.sectionTwo}>{data}</View>
        <View style={styles.sectionThree}>{data}</View>
      </Page>
    </Document>
  );
  return (
    mlsData && (
      <>
        <Select
          variant="outline"
          onChange={(e) => setType(e.target.value)}
          value={type}
        >
          {types.map((type, index) => (
            <option key={"cheque_option_" + index} value={type}>
              {upperFirst(type)}
            </option>
          ))}
        </Select>
        <PDFDownloadLink
          document={<MyDoc type={"print"} />}
          fileName={"cheque_" + type + "_" + deal._id + ".pdf"}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download now!"
          }
        </PDFDownloadLink>
        <PDFViewer style={{ flexGrow: 1, width: "100%", height: "90vh" }}>
          <MyDoc />
        </PDFViewer>
      </>
    )
  );
};
export default Invoice;
